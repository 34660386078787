<template>
  <div ref="show-class">
    <div v-if="!asset.isReady">
      <img src="@/assets/img/spinner.png" alt="" class="spinner-load " >
    </div>
    <!-- <div v-show="asset.type && asset.isReady">
      <img :src="image"  class="img-video-fix"/>
    </div> -->
    <div v-show="asset.type === 'video' && asset.isReady">
     <video class="img-video-fix" ref="showVideo"  id="video" :src="asset.src" autoplay autobuffer />
    </div>
    <!-- <div class="bullet-list" v-if="listVideo.length > 1">
        <label v-for="list, index in listVideo" :key="index" :class="indexResult == index ? 'active': ''" @click="goToVideo(index)">
          &nbsp;
        </label>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      asset: {
        id: '',
        src: '',
        isReady: '',
        type: '',
        position: 0
      },
      listVideo: [],
      indexResult: 0
    }
  },
  props: {
    assetVideo: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    if (this.assetVideo.length > 0) {
      const list = this.assetVideo
      this.listVideo = list
      const divFirst = this.$refs.showVideo
      divFirst.classList.add('show-detail')
      this.asset = this.setToObjectAsset(list, this.asset, true)
      document.getElementById('video').onended = () => {
        divFirst.classList.remove('show-detail')
        const assetBefore = JSON.parse(JSON.stringify(this.asset))
        this.asset = ''
        setTimeout(() => {
          divFirst.classList.add('show-detail')
          this.asset = this.setToObjectAsset(list, assetBefore, false)
        }, 100)
      }
    }
    // console.log('data', this.assetVideo)
  },
  watch: {
    assetVideo (val) {
      // console.log(val)
      this.listVideo = val
      const divFirst = this.$refs.showVideo
      divFirst.classList.add('show-detail')
      this.asset = this.setToObjectAsset(val, this.asset, true)
      document.getElementById('video').onended = () => {
        divFirst.classList.remove('show-detail')
        const assetBefore = JSON.parse(JSON.stringify(this.asset))
        this.asset = ''
        setTimeout(() => {
          divFirst.classList.add('show-detail')
          this.asset = this.setToObjectAsset(val, assetBefore, false)
        }, 100)
      }
    }
  },
  methods: {
    goToVideo (index) {
      this.asset = this.setToObjectAsset(this.listVideo, this.asset, false, index)
    },
    setToObjectAsset (list, asset, play, position = null) {
      const assetIndex = list.findIndex(item => item.src === asset.src)
      console.log('video', list)
      console.log('asset', assetIndex)
      console.log('position', position)
      console.log('play', play)
      if (assetIndex < 0 && play) {
        console.log('aray null 1')
        if (asset.src) {
          // jika file lama sudah dihapus
          const index = asset.position < list.length ? asset.position : 0
          this.searchIndex(list, index)
          return this.addToIndex(list[this.indexResult], this.indexResult)
        } else {
          // belum ada sama sekali asset dijalankan
          this.searchIndex(list, 0)
          return this.addToIndex(list[this.indexResult], this.indexResult)
        }
      } else if (assetIndex >= 0 && play) {
        console.log('aray null 2')
        // ketika asset sudah mempunyai list dan minimal sudah dijalankan
        return this.addToIndex(list[assetIndex], assetIndex)
      } else if (position || position === 0) {
        console.log('aray null', list)
        this.searchIndex(list, position)
        return this.addToIndex(list[this.indexResult], this.indexResult)
      } else {
        console.log('aray null else')
        // ketika asset masuk ke step berikut nya
        const nextAsset = asset.position + 1 < list.length ? asset.position + 1 : 0
        this.searchIndex(list, nextAsset)
        return this.addToIndex(list[this.indexResult], this.indexResult)
      }
    },
    addToIndex (item, index) {
      return {
        ...item,
        position: index
      }
    },
    searchIndex (item, index) {
      if (item[index].isReady) {
        this.indexResult = index
      } else {
        const nextIndex = item.length > index + 1 ? index + 1 : 0
        this.searchIndex(item, nextIndex)
      }
    }
  }
}
</script>

<style>
    .active{
       background: #636363 !important;
    }
</style>
