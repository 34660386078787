<template>
    <div class="home">
      <video-home class="video-position" :asset-video="listVideo"></video-home>
    </div>
    <div class="position-button-dvh" @click="toMenu">
      <div class="click-here">
        <div class="sprite-tap2 tap-1 " :class="isAnimation? 'tap-1animation': ''" v-if="isShow == 1"></div>
        <div class="sprite-tap2 tap-3" v-else></div>
        <label class="fs-32 fw-600 tap-text line-h39">Tap Anywhere to start</label>
      </div>
    </div>
    <info-animation-down :text-info="$l.detectLanguage('scanQR')"  :is-listen-qr="true"/>
</template>

<script>
import VideoHome from '@/components/VideoHome'
import { mapState, mapActions } from 'vuex'
import InfoAnimationDown from '@/components/Button/InfoAnimationDown'
export default {
  name: 'Home',
  components: {
    // FooterInfo,
    InfoAnimationDown,
    VideoHome
  },
  data () {
    return {
      isShow: 1,
      isAnimation: false
    }
  },
  computed: {
    ...mapState({
      listVideo: state => state.listVideo
    })
  },
  methods: {
    toMenu () {
      window.machineKiosk.send({
        type: 'click:everywhere'
      })
      // this.$router.push({ name: 'Menu' })
    },
    ...mapActions({
      getVideo: 'GET_VIDEO_IN_INDEXDB_AND_REPO'
    })
  },
  mounted () {
    console.log('masukkk data', this.listVideo)
    // this.getVideo()
    console.log(this.listVideo)
    setInterval(() => {
      this.isShow = this.isShow + 1 <= 2 ? this.isShow + 1 : 1
      if (this.isShow === 1) {
        setTimeout(() => {
          this.isAnimation = true
        }, 200)
      } else {
        this.isAnimation = false
      }
    }, 750)
  }
}
</script>
